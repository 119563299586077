<!--
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-25 09:05:47
 * @LastEditors: 李云飞
 * @LastEditTime: 2020-09-25 17:26:33
-->
<template>
  <Modal v-model="isShowModal"
    title="旗舰班-专业选择"
    :mask-closable="false"
    footer-hide
    class-name="modal-style">
    <p class="tips">请选择您要购买哪个专业的<span>{{planName}}</span>：</p>
    <ul class="major-list">
      <li type="border"
        v-for="item in childMajor"
        :key="item.majorId"
        @click="checkChildMajor(item)">
        {{item.majorName}}
      </li>
    </ul>
  </Modal>
</template>

<script>

export default {
  name: 'ChildMajorModal',
  components: {},
  props: {},
  data () {
    return {
      isShowModal: false,
      planName: null,
      childMajor: []
    };
  },
  computed: {},
  watch: {},
  filters: {},
  created () {
  },
  mounted () {
  },
  methods: {

    showModal (childMajor, planIndex, planName) {
      this.childMajor = childMajor;
      this.planIndex = planIndex;
      this.planName = planName;
      this.isShowModal = true;
    },

    // 选择二级专业
    checkChildMajor (item) {
      const coursePlanId = item.childPlanId[this.planIndex];
      this.$emit('checkChildMajor', coursePlanId);
      this.isShowModal = false;
    }
  }
}
</script>
<style lang='less' scoped>
/deep/.modal-style {
  .ivu-modal-content {
    border-radius: 4px;
    overflow: hidden;
    .ivu-modal-close {
      top: 14px;
    }
    .ivu-modal-header {
      height: 68px;
      padding: 0;
      border-bottom: none;
      background-color: #f7f7f7;
      .ivu-modal-header-inner {
        padding-left: 30px;
        height: 68px;
        line-height: 68px;
        font-size: 18px;
        color: #333;
      }
    }
    .ivu-modal-body {
      padding: 24px 30px 30px;
      height: 320px;
      display: flex;
      flex-direction: column;
      .tips {
        color: #999;
        margin-bottom: 20px;
        span {
          color: #ff572a;
        }
      }
      .major-list {
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        li {
          padding: 0 15px;
          height: 34px;
          line-height: 32px;
          border: 1px solid #ccc;
          border-radius: 4px;
          color: #666;
          margin-right: 20px;
          margin-bottom: 15px;
          cursor: pointer;
          &:hover {
            color: @main-color;
            border-color: @main-color;
          }
        }
      }
      .major-list::-webkit-scrollbar {
        width: 8px;
      }
    .major-list::-webkit-scrollbar-track {
      background-color: #e5e3e3;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }
      .major-list::-webkit-scrollbar-thumb {
        background-color: #61d2a6;
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
      }
    }
  }
}
</style>
