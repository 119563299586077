<!--
 * @Description: 每部分的专业列表
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-22 10:15:24
 * @LastEditors: hanyu
 * @LastEditTime: 2021-01-21 11:28:17
-->
<template>
  <div class="major-bar">
    <ul class="major-list">
      <li v-for="(item, index) in majorList" :key="index" :class="{ active: item.majorId === activeMajorId }"
          @click="checkMajor(item.majorId, index)">
        <a :title="item.majorName">{{ item.majorName }}</a>
      </li>
    </ul>
    <template v-if="showMore">
      <nuxt-link v-if="isInside" class="more-button" :to="moreLink">
        查看更多
        <Icon type="ios-arrow-forward"/>
      </nuxt-link>
      <div v-else class="more-button" @click="openExternalLink">
        查看更多
        <Icon type="ios-arrow-forward"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SectionMajor',
  components: {},
  filters: {},
  props: {
    // 父组件传入的专业数据
    majorList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 是否显示[查看更多]链接按钮
    showMore: {
      type: Boolean,
      default: false
    },
    // 点击更多跳转的url
    moreLink: {
      type: String,
      default: '/course'
    },
    // 查看更多是否内部链接
    isInside: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      activeMajorId: null
    };
  },
  computed: {},
  watch: {
    majorList: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0) {
          this.activeMajorId = newVal[0].majorId;
        }
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    // 选择专业改变
    checkMajor(majorId, index) {
      if (majorId != this.activeMajorId) {
        this.activeMajorId = majorId;
        const params = {majorId, index};
        this.$emit('changeMajor', params);
      }
    },

    // nuxt-link不能打开外部链接；用这个方法打开
    openExternalLink() {
      const tempWindow = window.open('_blank');
      tempWindow.location = this.moreLink;
    }
  }
};
</script>
<style lang="less" scoped>
.major-bar {
  height: 40px;
  display: flex;
  align-items: center;

  .major-list {
    flex: 1;
    display: flex;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }

    li {
      flex-shrink: 0;
      height: 40px;
      line-height: 38px;
      border: 1px solid #ededed;
      border-radius: 4px;
      margin-right: 20px;
      color: #666;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;

      &:last-child {
        margin-right: 0;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0 12px;
        color: #666;
      }

      &.active {
        line-height: 40px;
        background: @main-gradient;
        border: none;
        color: #fff;

        a {
          color: #fff;
        }
      }
    }
  }

  .more-button {
    color: #999;
    cursor: pointer;
  }
}
</style>
