export const PLAN_TYPE_ENUM = {
  XUNMENG: {
    value: 1,
    desc: '寻梦计划'
  },
  ZHUIMENG: {
    value: 2,
    desc: '追梦计划'
  },
  YUANMENG_A: {
    value: 3,
    desc: '圆梦A计划'
  },
  YUANMENG_B: {
    value: 4,
    desc: '圆梦B计划'
  }
}

export const arrData = [
  {
    majorName: '医师资格',
    majorId: 1000086,
    planData: [
      {
        planType: PLAN_TYPE_ENUM.XUNMENG.value,
        planName: '寻梦计划',
        planPrice: 1380,
        planForm: '直播+录播',
        planPromise: null,
        planTag: ['每日一练', '阶段测评', '章节练习', '10小时答疑', '模拟试卷']
      },
      {
        planType: PLAN_TYPE_ENUM.ZHUIMENG.value,
        planName: '追梦计划',
        planPrice: 1980,
        planForm: '直播+录播+网课',
        planPromise: '当期报名或技能考试未过，次年免费重学',
        planTag: ['每日一练', '阶段测评', '章节练习', '10小时答疑', '模拟试卷', '当期报名或技能考试未过 次年免费重学']
      },
      {
        planType: PLAN_TYPE_ENUM.YUANMENG_A.value,
        planName: '圆梦A计划',
        planPrice: 8800,
        planForm: '直播+录播+网课',
        planPromise: '当期考试未过，全额退费',
        planTag: ['每日一练', '阶段测评', '章节练习', '10小时答疑', '模拟试卷', '入学测评', '社群督学服务', '月度知识点回顾', '带教刷题', '当期考试未过 全额退费']
      },
      {
        planType: PLAN_TYPE_ENUM.YUANMENG_B.value,
        planName: '圆梦B计划',
        planPrice: 5800,
        planForm: '直播+录播+网课',
        planPromise: '当期考试未过，次年免费重学',
        planTag: ['每日一练', '阶段测评', '章节练习', '10小时答疑', '模拟试卷', '入学测评', '社群督学服务', '月度知识点回顾', '带教刷题', '当期考试未过 次年免费重学']
      }
    ],
    childMajor: [
      {
        majorName: '临床执业',
        majorId: 1000089,
        childPlanId: [248, 249, 250, 251]
      },
      {
        majorName: '临床助理',
        majorId: 1000090,
        childPlanId: [252, 253, 254, 255]
      },
      {
        majorName: '中医执业',
        majorId: 1034968,
        childPlanId: [256, 257, 258, 259]
      },
      {
        majorName: '中医助理',
        majorId: 1034969,
        childPlanId: [260, 261, 262, 263]
      },
      {
        majorName: '中西医执业',
        majorId: 1034970,
        childPlanId: [264, 265, 266, 267]
      },
      {
        majorName: '中西医助理',
        majorId: 1034971,
        childPlanId: [268, 269, 270, 271]
      },
      {
        majorName: '口腔执业',
        majorId: 1034972,
        childPlanId: [272, 273, 274, 275]
      },
      {
        majorName: '口腔助理',
        majorId: 1034973,
        childPlanId: [276, 277, 278, 279]
      }
    ]
  },
  {
    majorName: '乡村全科',
    majorId: 1034974,
    planData: [
      {
        planType: PLAN_TYPE_ENUM.XUNMENG.value,
        planName: '寻梦计划',
        planPrice: 1180,
        planForm: '直播+录播',
        planPromise: null,
        planTag: ['每日一练', '阶段测评', '章节练习', '10小时答疑', '模拟试卷'],
        planId: 280
      },
      {
        planType: PLAN_TYPE_ENUM.ZHUIMENG.value,
        planName: '追梦计划',
        planPrice: 1780,
        planForm: '直播+录播+网课',
        planPromise: '当期报名或技能未过，次年免费重学',
        planTag: ['每日一练', '阶段测评', '章节练习', '10小时答疑', '模拟试卷', '当期报名或技能未过 次年免费重学'],
        planId: 281
      },
      {
        planType: PLAN_TYPE_ENUM.YUANMENG_A.value,
        planName: '圆梦A计划',
        planPrice: 6800,
        planForm: '直播+录播+网课',
        planPromise: '当期考试未过，全额退费',
        planTag: ['每日一练', '阶段测评', '章节练习', '10小时答疑', '模拟试卷', '入学测评', '社群督学服务', '月度知识点回顾', '带教刷题', '当期考试未过 全额退费'],
        planId: 282
      },
      {
        planType: PLAN_TYPE_ENUM.YUANMENG_B.value,
        planName: '圆梦B计划',
        planPrice: 3800,
        planForm: '直播+录播+网课',
        planPromise: '当期考试未过，次年免费重学',
        planTag: ['每日一练', '阶段测评', '章节练习', '10小时答疑', '模拟试卷', '入学测评', '社群督学服务', '月度知识点回顾', '带教刷题', '当期考试未过 次年免费重学'],
        planId: 283
      }
    ]
  },
  {
    majorName: '执业护士资格',
    majorId: 1016622,
    planData: [
      {
        planType: PLAN_TYPE_ENUM.XUNMENG.value,
        planName: '寻梦精品班',
        planPrice: 680,
        planForm: '直播',
        planPromise: null,
        planTag: ['入学测试', '高频考点', '每日一测', '章节练习', '考前押题', '模拟试卷', '机考模拟', '考点速记', '10小时答疑'],
        planId: 410
      },
      {
        planType: PLAN_TYPE_ENUM.ZHUIMENG.value,
        planName: '追梦通关班',
        planPrice: 1280,
        planForm: '直播+网课',
        planPromise: null,
        planTag: ['入学测试', '高频考点', '每日一测', '章节练习', '考前押题', '模拟试卷', '机考模拟', '考点速记', '10小时答疑'],
        planId: 415
      },
      {
        planType: PLAN_TYPE_ENUM.YUANMENG_A.value,
        planName: '圆梦取证-退费班',
        planPrice: 2980,
        planForm: '直播+网课',
        planPromise: '当期考试未过，全额退费',
        planTag: ['入学测试', '高频考点', '每日一测', '章节练习', '考前押题', '模拟试卷', '机考模拟', '考点速记', '考点速记', '10小时答疑', '社群督学服务', '当期考试未过，全额退费'],
        planId: 417
      },
      {
        planType: PLAN_TYPE_ENUM.YUANMENG_B.value,
        planName: '圆梦取证-重读班',
        planPrice: 1980,
        planForm: '直播+网课',
        planPromise: '当期考试未过，免费重读',
        planTag: ['入学测试', '高频考点', '每日一测', '章节练习', '考前押题', '模拟试卷', '机考模拟', '考点速记', '10小时答疑', '社群督学服务', '当期考试未过，免费重读'],
        planId: 422
      }
    ]
  },
  {
    majorName: '执业药师',
    majorId: 1000087,
    planData: [
      {
        planType: PLAN_TYPE_ENUM.XUNMENG.value,
        planName: '寻梦计划',
        planPrice: 1280,
        planForm: '网课',
        planPromise: null,
        planTag: ['章节练习', '10小时答疑']
      },
      {
        planType: PLAN_TYPE_ENUM.ZHUIMENG.value,
        planName: '追梦计划',
        planPrice: 1680,
        planForm: '网课',
        planPromise: '当期考试未过，次年免费重读',
        planTag: ['章节练习', '10小时答疑', '当期考试未过 次年免费重读']
      }
    ],
    childMajor: [
      {
        majorName: '执业西药师',
        majorId: 1016619,
        childPlanId: [310, 362]
      },
      {
        majorName: '执业中药师',
        majorId: 1016620,
        childPlanId: [311, 363]
      }
    ]
  },
  {
    majorName: '卫生资格',
    majorId: 1016621,
    planData: [
      {
        planType: PLAN_TYPE_ENUM.XUNMENG.value,
        planName: '寻梦精品班',
        planPrice: 980,
        planForm: '直播+网课',
        planPromise: null,
        planTag: ['每日一测', '章节练习', '10小时答疑']
      },
      {
        planType: PLAN_TYPE_ENUM.ZHUIMENG.value,
        planName: '追梦通关班',
        planPrice: 1580,
        planForm: '直播+网课',
        planPromise: '考试未过，次年免费重学',
        planTag: ['每日一测', '章节练习', '10小时答疑', '考试未过，次年免费重学']
      }
    ],
    childMajor: [
      {
        majorName: '初级护师（203）',
        majorId: 1034976,
        childPlanId: [480, 485]
      },
      {
        majorName: '主管护师（368）',
        majorId: 1034977,
        childPlanId: [492, 495]
      },
      {
        majorName: '检验技士（105）',
        majorId: 22749594,
        childPlanId: [425, 437]
      },
      {
        majorName: '检验技师（207）',
        majorId: 22749826,
        childPlanId: [440, 446]
      },
      {
        majorName: '主管检验师（379）',
        majorId: 22749863,
        childPlanId: [451, 459]
      },
      {
        majorName: '放射医学技士（104）',
        majorId: 22749901,
        childPlanId: [461, 464]
      },
      {
        majorName: '放射医学技师（206）',
        majorId: 22749925,
        childPlanId: [467, 470]
      },
      {
        majorName: '主管放射技师（376）',
        majorId: 22749941,
        childPlanId: [488, 489]
      },
      {
        majorName: '康复医学治疗与技术士（107）',
        majorId: 23876000,
        childPlanId: [478, 481]
      },
      {
        majorName: '康复医学治疗与技术师（209）',
        majorId: 23876001,
        childPlanId: [483, 484]
      },
      {
        majorName: '康复医学治疗与技术中级（381）',
        majorId: 23876002,
        childPlanId: [486, 487]
      },
      {
        majorName: '内科学（303）',
        majorId: 1034989,
        childPlanId: [409, 418]
      },
      {
        majorName: '心血管内科学（304）',
        majorId: 1034990,
        childPlanId: [430, 432]
      },
      {
        majorName: '呼吸内科学（305）',
        majorId: 1034991,
        childPlanId: [435, 442]
      },
      {
        majorName: '消化内科学（306）',
        majorId: 1034992,
        childPlanId: [447, 452]
      },
      {
        majorName: '肾内科学（307）',
        majorId: 1034993,
        childPlanId: [456, 457]
      },
      {
        majorName: '神经内科学（308）',
        majorId: 1034994,
        childPlanId: [490, 491]
      },
      {
        majorName: '内分泌学（309）',
        majorId: 1034995,
        childPlanId: [493, 494]
      },
      {
        majorName: '血液病学（310）',
        majorId: 1034996,
        childPlanId: [477, 496]
      },
      {
        majorName: '结核病学（311）',
        majorId: 1034997,
        childPlanId: [472, 474]
      },
      {
        majorName: '传染病学（312）',
        majorId: 1034998,
        childPlanId: [466, 469]
      },
      {
        majorName: '风湿与临床免疫学（313）',
        majorId: 1034999,
        childPlanId: [460, 462]
      },
      {
        majorName: '职业病学（314）',
        majorId: 1035000,
        childPlanId: [449, 455]
      },
      {
        majorName: '普通外科学（317）',
        majorId: 1035009,
        childPlanId: [412, 420]
      },
      {
        majorName: '骨外科学（318）',
        majorId: 1035010,
        childPlanId: [424, 429]
      },
      {
        majorName: '胸心外科学（319）',
        majorId: 1035011,
        childPlanId: [431, 433]
      },
      {
        majorName: '神经外科学（320）',
        majorId: 1035012,
        childPlanId: [434, 436]
      },
      {
        majorName: '泌尿外科学（321）',
        majorId: 1035013,
        childPlanId: [438, 439]
      },
      {
        majorName: '小儿外科学（322）',
        majorId: 1035014,
        childPlanId: [441, 443]
      },
      {
        majorName: '烧伤外科学（323）',
        majorId: 1035015,
        childPlanId: [445, 448]
      },
      {
        majorName: '整形外科学（324）',
        majorId: 1035016,
        childPlanId: [450, 454]
      },
      {
        majorName: '妇产科学（330）',
        majorId: 1035017,
        childPlanId: [414, 416]
      },
      {
        majorName: '儿科医学（332）',
        majorId: 1035018,
        childPlanId: [411, 413]
      },
      {
        majorName: '全科医学（301）',
        majorId: 1035019,
        childPlanId: [423, 426]
      },
      {
        majorName: '中医内科学（315）',
        majorId: 1035020,
        childPlanId: [427, 428]
      },
      {
        majorName: '口腔主治（353）',
        majorId: 23581524,
        childPlanId: [419, 421]
      }
    ]
  },
  {
    majorName: '健康管理师',
    majorId: 1035023,
    planData: [
      {
        planType: PLAN_TYPE_ENUM.XUNMENG.value,
        planName: '寻梦计划',
        planPrice: 980,
        planForm: '直播+网课',
        planPromise: null,
        planTag: ['每日一练', '阶段测评', '10小时答疑'],
        planId: 284
      },
      {
        planType: PLAN_TYPE_ENUM.ZHUIMENG.value,
        planName: '追梦计划',
        planPrice: 1380,
        planForm: '直播+网课',
        planPromise: null,
        planTag: ['每日一练', '阶段测评', '10小时答疑'],
        planId: 285
      }
    ]
  }
]
